html {
  height: 100%;  
  min-height: 100%;
}
*:focus {
  outline: 0;
}
@media (max-width: 374.98px) {
  /* body {
    background: url(./img/scene_400.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; 
  } */
  body:after{
    content:"";
    position:fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height:100vh; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url(./img/scene_400.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
@media (min-width: 375px) and (max-width: 767.98px) {
  body:after{
    content:"";
    position:fixed; 
    top:0;
    height:100vh; 
    left:0;
    right:0;
    z-index:-1; 
    background: url(./img/scene_600.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}  
@media (min-width: 768px) and (max-width: 991.98px) {
  body:after{
    content:"";
    position:fixed;
    top:0;
    height:100vh;
    left:0;
    right:0;
    z-index:-1;
    background: url(./img/scene_800.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  body:after{
    content:"";
    position:fixed;
    top:0;
    height:100vh;
    left:0;
    right:0;
    z-index:-1;
    background: url(./img/scene_1000.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  body:after{
    content:"";
    position:fixed;
    top:0;
    height:100vh;
    left:0;
    right:0;
    z-index:-1;
    background: url(./img/scene_1440.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
@media (min-width: 1400px) {
  body:after{
    content:"";
    position:fixed;
    top:0;
    height:100vh;
    left:0;
    right:0;
    z-index:-1;
    background: url(./img/scene_max.jpg) center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
#cloudsWrapper {
  overflow: hidden;
  position: fixed;
  top:0;
  /* z-index: 10; */
}
/*Clouds position correct*/
.header,
.main,
#shareIt,
#installApp,
.footer {
  z-index: 10; 
}
#cloudsToggle {
  z-index: 11;
}
.clouds {
  background: url(./img/StormSky.png) repeat-x;
  width: 5760px;
  /* height: 1080px; */
  height: calc(100vh);
  animation: slide 60s linear infinite; 
}
@media (min-width: 1200px) {
  .clouds {
    height: calc(100vh);
    /* height: 1080px; */
  }
}
@keyframes slide{
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: translate3d(-1920px, 0, 0);
  }
}
/* Main grid rules */
.mainGrid {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;  
  grid-template-areas:
    "page" 
    "footer";
}
.page {
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
}
.footer {
  grid-row-start: 2;
  grid-row-end: 3;
  justify-self: center;
}
/* / Main grid rules */

/* Sub grid rules */
.container {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 2fr;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    "cloudsWrapper cloudsWrapper cloudsWrapper"
    "header header header" 
    ". main . ";
}
.cloudsWrapper {
  grid-column-start: 1;
  grid-column-end: 4;
}
.header {
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    "logo nav nav"; 
}
.logo {
  grid-area: logo;
  grid-column-start: 1;
  grid-column-end: 2;
  padding-left: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.nav {
  grid-area: nav;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.main {
  grid-area: main;
  grid-column-start: 2;
  grid-column-end: 3;
}
@media (max-width: 991.98px) {
  .container {
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;  
    grid-template-areas: 
      "cloudsWrapper"
      "header"
      "main";
  }
  .header {
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "nav"; 
  }
  .logo {
    grid-area: logo;
    grid-row-start: 1;
    grid-row-end: 2; 
    justify-self: center;
    padding-left: 0;
    text-align: center;
    margin-bottom: 0em;
  }
  .nav {
    grid-area: nav;
    grid-row-start: 2;
    grid-row-end: 3; 
    justify-self: center;
    margin-top: 0em;
    margin-bottom: 0em;
  }
  .main {
    grid-area: main;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-bottom: 1.5em;
  }
}
/* / Sub grid rules */
body, ul, li, #root {
  margin: 0;
  padding: 0;
}
h1 {
  line-height: 1.2em;
}
.typo-nav {
  font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.75em;
  font-weight: 700;
  line-height: 1.5em;
  color: rgba(255,255,255,1);
}
.typo {
  font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.5em;
  color: rgba(255,255,255,1);
}
.name {
  font-style: italic;
}
.typo .name {
  font-weight: 700;
}
.block {
  display: block;
}
.copy {
  font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.5em;
  color: rgba(255,255,255,1);
  margin: 2rem 0;
}
.copy a {
  text-decoration: underline;
}
@media (max-width: 991.98px) {
  .typo {
    font-size: 1.2em !important;
    font-weight: 500;
    line-height: 1.5em;
  }
  .typo-nav {
    font-size: 1.2em !important;
    font-weight: 700;
    line-height: 1.5em;
  }
  .copy {
    font-size: .8em;
  }
}
.storybox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fadeIn {
  /* text-align: center; */
  text-align: left;
  opacity: 0;
  display: block;
  animation: slidein 1s ease-out forwards;
}
/* .fadeIn > p {
  white-space: pre-wrap;
} */
@keyframes slidein {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@media (max-width: 991.98px) {
  .fadeIn > p,
  .fadeIn > article {
    margin-left: 1em;
    margin-right: 1em;
  }
  @keyframes slidein {
    0% {
      opacity: 0;
      transform: translateY(-35px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
/* Main Btn */
#newquote {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 2.5em;
  height: 3.5em;
  width: 11.5em;
  background: rgba(6,29,53,.75);
  color: rgba(255,255,255,1); 
  transition: all .2s ease-in-out;
}
#newquote:hover {
  -webkit-box-shadow: 0px 10px 54px -7px rgba(139,194,212,.75);
  -moz-box-shadow: 0px 10px 54px -7px rgba(139,194,212,.75);
  box-shadow: 0px 10px 54px -7px rgba(139,194,212,.75);
  background: rgba(6,29,53,.53);  
  color: rgba(157,220,242,1); 
  cursor: pointer;
  transform: scale(1.05);
}
#shareIt {
  position: fixed;
  right: 2rem;
  bottom: 0rem;
}
#cloudsToggle {
  position: fixed;
  right: 2rem;
  top: 2.5rem; 
}
#installApp {
  position: fixed;
  left: 4.5rem;
  bottom: 2.5rem;
}
/* iOS prompt */
.ios-prompt {
  border-radius: 6px;
  background-image: linear-gradient(-30deg, rgba(2,15,25,.75) 0%, rgba(33,79,98,.65) 100%);
  box-shadow: 10px 10px 24px rgba(2,15,25, 0.5);
  color: #fff;
  display: none;
  /* padding: 0.8rem 1rem 0 0.5rem; */
  padding: .5rem 1rem;
  text-decoration: none;
  font-size: .8rem;
  /* font-size: 16px; */
  text-align: center;

  position: fixed;
  /* left: 4.5rem; */
  /* bottom: 2.5rem; */
  /* margin: 0 auto 1rem; */
  margin: 0 auto;
  left: 1rem;
  right: 1rem;
  /* bottom: 1rem; */
  z-index: 11;
}
.ios-prompt div:first-child {
  font-size: 3rem;
  font-weight: lighter;
}
.ios-prompt span {
  font-weight: bold;
  display: block;
}
.ios-prompt span:last-child {
  margin: .5rem 0 1rem;
  color: #9DDCF2;
}
.ios-prompt svg {
 width: 18px;
 height: 24px;
 fill: #9DDCF2; 
 transform: translate(0, .35rem);
 padding: 0 .35rem;
}
@media (max-width: 991.98px) {
  a#shareIt {
    position: fixed;
    right: 0rem;
    bottom: 0rem;
  }
  #installApp {
    position: fixed;
    left: 1.5rem;
    bottom: 2.5rem;
  }
  #cloudsToggle {
    position: fixed;
    right: .5rem;
    top: 2rem; 
  }
}
a {
  text-decoration: none;
  color: rgba(157,220,242,1);
}
a:hover, a:active {
  color: rgba(255,255,255,1); 
}
.button a {
  color: rgba(255,255,255,1);
}
.button a:hover {
  color: rgba(157,220,242,1); 
}
/* .button {
  color: rgba(255,255,255,1);
}
.button:hover {
  color: rgba(157,220,242,1); 
} */
ul {
  list-style: none;
  margin-top: 0.25em; 
}
ul li {
  display: inline;
  padding-right: 1em; 
}
@media (max-width: 991.98px) {
  ul {
    padding-left: 0rem;
    margin-top: 0.75em;
    text-align: center;
  }
  ul li {
    display: inline;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  ul li a {
    text-decoration: underline;
    color: rgba(157,220,242,1);
  }
}
.social {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}
@media (max-width: 991.98px) {
  .social {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
.unvisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.off {
  display: none;
}
.on {
  display: block;
}
svg.social-icon {
  width: 4em;
  height: 4em;
  fill: rgba(157,220,242,1);
}
svg.social-icon:hover {
  fill: rgba(255,255,255,1); 
}
@media (max-width: 991.98px) {
  svg.social-icon {
    width: 3em;
    height: 3em;
  }
}
.install-app button {
  color: aliceblue;
  font-size: 1rem;
  width: 10rem;
  height: 3rem;
  background-color: blueviolet;
  margin: 2em 2em;
}
.hidden {
  display: none;
}

/* Pretty Tooltips */
[data-tooltip] {
  margin-bottom: 3rem;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 1rem 2rem;
  line-height: 1.3rem;
  min-width: 10rem;
  max-width: 15rem;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  background-image: linear-gradient(-30deg, rgba(2,15,25,.65) 0%, rgba(33,79,98,.45) 100%);
  box-shadow: 10px 10px 24px rgba(2,15,25, 0.5);
  color: #fff;
  transform: translate(-50%, -5px) scale(0.5);
}

@media (max-width: 991.98px) {
  [data-tooltip]:before {
    content: attr(data-tooltip); 
    padding: 1rem 1rem;
    line-height: 1rem;
    min-width: 5rem;
    max-width: 10rem;
    font-size: .8rem;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    background-image: linear-gradient(-30deg, rgba(2,15,25,.65) 0%, rgba(33,79,98,.45) 100%);
    box-shadow: 10px 10px 24px rgba(2,15,25, 0.5);
    color: #fff;
    transform: translate(-50%, -5px) scale(0.5);
  }
  a#cloudsToggle[data-tooltip]:before,
  div#installApp[data-tooltip]:before,
  a#shareIt[data-tooltip]:before {
    display: none;
  }
}

/* Tooltip arrow */
[data-tooltip]:after {
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top;   /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* Slide down effect only on mouseenter (NOT on mouseleave) */
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it.
*/

/*
  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 1rem);
  bottom: 70%;
}
@media (max-width: 991.98px) {
  [data-tooltip-location="right"]:before,
  [data-tooltip-location="right"]:after {
    right: calc(70%);
  }
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(80% + 5px);
  bottom: 70%; 
}
@media (max-width: 991.98px) {
  [data-tooltip-location="right"]:before,
  [data-tooltip-location="right"]:after {
    left: calc(80%);
  }
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}











